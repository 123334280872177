'use client';
/* Release: 63880259 (Latest – unreleased) */

import * as Sentry from '@sentry/react';
import Page from 'components/Page';
import { ProductPlanDemo } from 'components/ProductPlanDemo';
import { RequestSubscriptionPlanExtensionCTA } from 'components/RequestSubscriptionPlanExtensionCTA';
import { RouterLink } from 'components/RouterLink';
import { TabsWithContent } from 'components/design-system/Tabs';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';
import { useEffect, useState } from 'react';
import { useListSubscriptions } from 'src/api/frontend';
import { Alert, Breadcrumbs } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import {
  ExpiryDisplay,
  canRequestPlanExtension,
  getAlertBannerExpiryInDaysText,
  getMostRelevanSubscription,
  showUpdateNow,
} from 'utils/subscription_plan';
import { CurrentSubscription } from './components/CurrentSubscription';
import { FAQs } from './components/FAQ';
import { ProductPlans } from './components/ProductPlans';

function ProductPlansSubscriptions() {
  const { logEvent } = AnalyticsEventLogger();

  type tabType = 'subscription' | 'billing' | 'faqs';
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('view');
  const [productTab, setProductTab] = useState(paramValue);

  const { account, currentOrganization } = useUserAccountState();
  const { data, error: subscriptionError } = useListSubscriptions(
    currentOrganization.slug,
    {},
    {
      request: { headers: { authorization: 'Bearer ' + account?.token } },
    },
  );
  const mainSubscription = getMostRelevanSubscription(data?.subscriptions);

  const productTabs = [
    {
      id: 'subscription',
      title: 'Current Subscription',
      content: (
        <CurrentSubscription
          subscription={mainSubscription}
          totalEntitlement={data?.total_entitlement}
        />
      ),
    },
    {
      id: 'billing',
      title: 'Product Plans',
      content: <ProductPlans subscription={mainSubscription} />,
    },
    {
      id: 'faqs',
      title: 'FAQs',
      content: <FAQs />,
    },
  ];

  const upgradeNowPath = useAccountIdRoute(
    '/orgs/:orgId/subscriptions?view=billing',
  );

  const isDemo = mainSubscription?.plan === 'Demo' && !subscriptionError;
  useEffect(() => {
    if (paramValue) setProductTab(paramValue);
  }, [paramValue]);

  const alertBannerText = {
    headerText: getAlertBannerExpiryInDaysText(
      mainSubscription?.expiry,
      mainSubscription?.name,
    ),
    descriptionTextUpgradeNow: `Upgrade Now`,
    descriptionTextRequestExtension: `Request Extension`,
  };

  if (subscriptionError) Sentry.captureException(subscriptionError);

  // @ts-ignore
  return (
    <Page title="Billing & Subscriptions">
      <div className="h-full flex-col gap-[24px] shadow-shadow pl-[12px] pr-[12px] py-[32px] items-start flex bg-default-background ml-8">
        <div className="w-full flex-col gap-[24px] items-start flex">
          <Breadcrumbs>
            <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
            <Breadcrumbs.Divider />
            <Breadcrumbs.Item active={true}>
              Billing & Subscriptions
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <div className="w-full flex-col items-start flex">
            <span className="w-full text-section-header">
              Billing & Subscriptions
            </span>
            <span className="w-full text-body font-body text-subtext-color">
              Manage your subscription & see available plans
            </span>
          </div>
          {data?.subscriptions && (
            <div className="contents">
              {showUpdateNow(mainSubscription?.plan) && (
                <Alert
                  className="max-w-[1400px]"
                  title={alertBannerText.headerText}
                  variant={
                    ExpiryDisplay(mainSubscription?.expiry) !== ''
                      ? 'error'
                      : 'neutral'
                  }
                  description={
                    <>
                      {isDemo ? (
                        <ProductPlanDemo view={'Subscription Plan'} />
                      ) : (
                        <>
                          <RouterLink
                            className="hoverable-link text-label font-label"
                            to={upgradeNowPath}
                            onClick={() => {
                              logEvent('product-plans-alert-banner-click', {
                                text: alertBannerText.descriptionTextUpgradeNow,
                              });
                            }}
                          >
                            {alertBannerText.descriptionTextUpgradeNow}
                          </RouterLink>
                          {mainSubscription &&
                            canRequestPlanExtension(mainSubscription) && (
                              <>
                                <span className="text-body text-default-font">
                                  {' '}
                                  or{' '}
                                </span>
                                <RequestSubscriptionPlanExtensionCTA
                                  clickHandler={() => {
                                    logEvent(
                                      'product-plans-alert-banner-click',
                                      {
                                        text: alertBannerText.descriptionTextRequestExtension,
                                      },
                                    );
                                  }}
                                  CTAType={'link'}
                                  description={
                                    alertBannerText.descriptionTextRequestExtension
                                  }
                                  subscription={mainSubscription}
                                />
                              </>
                            )}
                        </>
                      )}
                    </>
                  }
                />
              )}
            </div>
          )}
          <TabsWithContent
            initialTabId={productTab || undefined}
            onTabChange={(newTabId) => {
              setProductTab(newTabId as tabType);
              const url = new URL(`${window.location}`);
              url.searchParams.set('view', newTabId);
              window.history.pushState(null, '', url.toString());
              logEvent('product-plans-tab', {
                tab: newTabId,
              });
            }}
            tabs={productTabs}
          />
        </div>
      </div>
    </Page>
  );
}

export default ProductPlansSubscriptions;
