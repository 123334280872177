'use client';

import React, { useEffect, useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { TextArea } from 'subframe/components/TextArea';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useUserAccountState from 'hooks/useUserAccountState';
import { updateUpgradePlan } from 'api/frontend';
import * as Sentry from '@sentry/browser';
import { AtomicTooltip } from 'components/design-system';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider from 'components/FormProvider';

interface MarkUpgradePlanAsCompletedProps {
  open?: boolean;
  onClose?: () => void;
  upgradeId: string;
  mutatePlan: () => void;
  isFromLandingPage?: boolean;
}

function MarkUpgradePlanAsCompleted({
  open,
  onClose,
  upgradeId,
  mutatePlan,
  isFromLandingPage = false,
}: MarkUpgradePlanAsCompletedProps) {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(open);

  const markAsCompletedSchema = Yup.object().shape({
    comment: Yup.string().max(500).optional(),
  });

  const defaultValues: { comment: string } = {
    comment: '',
  };

  const methods = useForm<{ comment: string }>({
    resolver: yupResolver(markAsCompletedSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    clearErrors,
    resetField,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'marking the Upgrade Plan as Completed',
  );

  const MarkAsCompleted = async (data: { comment: string }) => {
    try {
      await updateUpgradePlan(
        upgradeId,
        { status: 'completed', status_comment: data.comment },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );
      logEvent('upgrade-plan-mark-as-completed', {
        planId: upgradeId,
      });
      enqueueSnackbar('Successfully marked Upgrade Plan as Completed', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      mutatePlan();
      closeDialog();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      closeDialog();
    }
  };
  if (!openDialog && errors?.comment) {
    // Reset errors when dialog is closed
    clearErrors();
  }

  const closeDialog = () => {
    onClose ? onClose() : setOpenDialog(false);
    resetField('comment');
  };

  if (open === false && openDialog === true) {
    setOpenDialog(false);
  }

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  return (
    <div style={{ display: 'contents' }}>
      {isFromLandingPage && (
        <AtomicTooltip tooltipContent="Mark the Upgrade Plan as Completed.">
          <IconButton
            size="medium"
            icon="FeatherCheck"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              event.preventDefault();
              setOpenDialog(true);
            }}
          />
        </AtomicTooltip>
      )}
      <Dialog open={openDialog} onOpenChange={closeDialog}>
        <Dialog.Content className="h-auto w-auto flex-none">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(MarkAsCompleted)}
          >
            <div className="flex w-full min-w-[384px] max-w-[448px] flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
              <div className="flex w-full items-start justify-between">
                <span className="text-subheader font-subheader text-default-font">
                  Ready to mark this Upgrade Plan as completed?
                </span>
                <IconButton
                  size="medium"
                  icon="FeatherX"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    closeDialog()
                  }
                />
              </div>
              {!isFromLandingPage && (
                <span className="text-body font-body text-default-font">
                  You have completed all the steps for this Upgrade Plan.
                </span>
              )}
              <span className="text-body font-body text-default-font">
                This action cannot be undone. The Upgrade Plan will become
                read-only once marked as Completed.
              </span>
              <div className="flex w-full flex-col items-start gap-2">
                <span className="text-body-bold font-body-bold text-subtext-color">
                  Comments (Optional)
                </span>
                <TextArea
                  className="h-auto w-full flex-none"
                  helpText={errors.comment?.message}
                  error={methods.getFieldState('comment').invalid}
                >
                  <TextArea.Input
                    className="h-auto min-h-[96px] w-full flex-none"
                    placeholder="Are there any additional details you would like to share?"
                    {...methods.register('comment')}
                  />
                </TextArea>
              </div>
              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  size="large"
                  variant="neutral-tertiary"
                  icon="FeatherX"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
                <Button
                  variant="brand-primary"
                  size="large"
                  icon="FeatherCheck"
                  onClick={handleSubmit(MarkAsCompleted)}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Mark as Complete
                </Button>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}

export default MarkUpgradePlanAsCompleted;
