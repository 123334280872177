import React, { useEffect, useState } from 'react';
import OktaOIDC from 'pages/security/OktaOidc';
import OktaSAML from 'pages/security/OktaSaml';
import { TabsWithContent } from 'components/design-system/Tabs';
import { getAccountSSOConfiguration } from 'api/frontend';
import * as Sentry from '@sentry/browser';
import { SSOConfiguration } from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import {
  OktaOIDCConnection,
  OktaSAMLConnection,
  SSOConnectionError,
} from 'pages/security/SSOConnections';
import DeleteSSOConnection from 'pages/security/DeleteSSOConnection';
import { Loader } from '@subframe/core';

interface SecuritySettingProps {
  view: string;
}

export enum SSOConfigurationStatusEnum {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DISABLED = 'disabled',
  ERROR = 'error',
  NOT_FOUND = 'not_found',
  PENDING_DELETE = 'pending-delete',
  UNKNOWN = 'unknown',
}

const mapSSOConfigurationStatus = (status: string) => {
  switch (status) {
    case 'active':
      return SSOConfigurationStatusEnum.ACTIVE;
    case 'inactive':
      return SSOConfigurationStatusEnum.INACTIVE;
    case 'pending':
      return SSOConfigurationStatusEnum.PENDING;
    case 'disabled':
      return SSOConfigurationStatusEnum.DISABLED;
    case 'error':
      return SSOConfigurationStatusEnum.ERROR;
    case 'not_found':
      return SSOConfigurationStatusEnum.NOT_FOUND;
    case 'pending-delete':
      return SSOConfigurationStatusEnum.PENDING_DELETE;
    default:
      return SSOConfigurationStatusEnum.UNKNOWN;
  }
};
const SecuritySetting: React.FC<SecuritySettingProps> = ({ view }) => {
  const [currentTab, setCurrentTab] = useState(
    view ? `${view}-oidc` : 'okta-saml',
  );
  const { account } = useUserAccountState();
  const [sSOConfigData, setSSOConfigData] = useState<SSOConfiguration>(
    {} as SSOConfiguration,
  );
  const [ssoStatus, setSSOStatus] = useState<SSOConfigurationStatusEnum>(
    SSOConfigurationStatusEnum.UNKNOWN,
  );
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${account.token}`,
  };
  const fetchConfig = async () => {
    try {
      const data = await getAccountSSOConfiguration('DEFAULT', { headers });
      setSSOConfigData(data);
      const status = mapSSOConfigurationStatus(data?.status);
      // Only trigger state change if status has changed
      ssoStatus !== status && setSSOStatus(status);
    } catch (e) {
      if (e.response?.status === 404) {
        setSSOStatus(SSOConfigurationStatusEnum.NOT_FOUND);
        Sentry.captureException('No SSO configuration found', { level: 'log' });
      } else {
        setSSOStatus(SSOConfigurationStatusEnum.ERROR);
        Sentry.captureException(e);
      }
    }
  };
  const SSO_TABS = [
    {
      id: 'okta-oidc',
      key: 'okta-oidc',
      title: 'Okta - OIDC',
      trackerEvent: 'okta-oidc-sso-tab',
      disabled: false,
      content: <OktaOIDC onSubmit={fetchConfig} ssoConfig={sSOConfigData} />,
    },
    {
      id: 'okta-saml',
      key: 'okta-saml',
      title: 'Okta - SAML (SP Initiated SSO)',
      trackerEvent: 'okta-saml-tab',
      disabled: false,
      content: <OktaSAML onSubmit={fetchConfig} ssoConfig={sSOConfigData} />,
    },
  ];

  useEffect(() => {
    if (ssoStatus !== SSOConfigurationStatusEnum.NOT_FOUND) {
      fetchConfig();
      let intervalId: NodeJS.Timeout;
      intervalId = setInterval(() => {
        fetchConfig();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [ssoStatus]);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <div className="flex w-full max-w-[1280px] flex-col gap-6">
        <span className="text-subheader font-subheader text-default-font">
          SSO Connection
        </span>
        <>
          {!sSOConfigData?.created &&
            ssoStatus === SSOConfigurationStatusEnum.UNKNOWN && <Loader />}
          {ssoStatus === SSOConfigurationStatusEnum.NOT_FOUND && (
            <span className="text-body font-body text-default-font">
              No SSO Connection configured.
            </span>
          )}
          {sSOConfigData?.created ? (
            <>
              {sSOConfigData?.okta_oidc && (
                <OktaOIDCConnection
                  created={sSOConfigData?.created}
                  config={sSOConfigData?.okta_oidc}
                  status={ssoStatus}
                />
              )}
              {sSOConfigData?.okta_saml && (
                <OktaSAMLConnection
                  created={sSOConfigData?.created}
                  config={sSOConfigData?.okta_saml}
                  status={ssoStatus}
                />
              )}
              {ssoStatus !== SSOConfigurationStatusEnum.PENDING_DELETE && (
                <DeleteSSOConnection
                  authToken={account.token}
                  onDelete={fetchConfig}
                />
              )}
            </>
          ) : (
            <>
              {ssoStatus === SSOConfigurationStatusEnum.ERROR && (
                <SSOConnectionError />
              )}
              {ssoStatus !== SSOConfigurationStatusEnum.ACTIVE &&
                ssoStatus !== SSOConfigurationStatusEnum.PENDING_DELETE &&
                ssoStatus !== SSOConfigurationStatusEnum.PENDING &&
                ssoStatus !== SSOConfigurationStatusEnum.DISABLED &&
                ssoStatus !== SSOConfigurationStatusEnum.INACTIVE &&
                ssoStatus !== SSOConfigurationStatusEnum.UNKNOWN && (
                  <TabsWithContent initialTabId={currentTab} tabs={SSO_TABS} />
                )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default SecuritySetting;
