import * as Sentry from '@sentry/browser';
import * as SubframeCore from '@subframe/core';
import { rescanCluster, submitFeedback } from 'api/frontend';
import { Cluster, RescanClusterRequest } from 'api/models';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import DeactivateClusterConfirmationDialog from 'components/dialogs/DeactivateClusterConfirmationDialog';
import InactiveClusterDialog from 'components/dialogs/InactiveClusterDialog';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import {
  ChangeClusterNameEnvironmentDialog,
  errorAgentConfigAlreadySet,
} from 'pages/clusters/dialogs/ChangeClusterNameEnvironmentDialog';
import React, { useEffect, useMemo, useState } from 'react';
import { DropdownMenu } from 'subframe/components/DropdownMenu';
import { IconButton } from 'subframe/components/IconButton';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { isClusterInactive } from 'utils/clusterStates';
import {
  getClusterExpiryStateBySupportLevel,
  getCurrentlyApplicableSupportLevel,
} from 'utils/clusterSupportLevelAndEol';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import { getTimeRemaining } from 'utils/getTimeRemaining';
import isExampleCluster from 'utils/isExampleCluster';
import { EnumEolState } from 'utils/types';

export interface AlertButtonTrayProps
  extends React.ComponentProps<typeof IconButton> {
  tooltipText?: string;
  className?: string;
  showExampleData: boolean;
  cluster: Cluster;
  lastScanDiff: number;
}

export function AlertButtonTray({
  tooltipText = 'EKS v1.19.15 has reached end of support 8 months ago (Aug 1, 2022)',
  className,
  variant,
  showExampleData,
  cluster,
  lastScanDiff,

  ...otherProps
}: AlertButtonTrayProps) {
  const versionToShow = extractMajorMinor(cluster?.version || '');
  const currSupportLevel = getCurrentlyApplicableSupportLevel(cluster);
  const timeLeft = getTimeRemaining(currSupportLevel?.end_date);

  return (
    <>
      {!showExampleData &&
        (getClusterExpiryStateBySupportLevel(cluster) ===
          EnumEolState.Warning ||
          getClusterExpiryStateBySupportLevel(cluster) ===
            EnumEolState.Error) && (
          <UnsupportedButton
            tooltipText={
              timeLeft.eosMessage(
                versionToShow,
                currSupportLevel?.name || '',
              )[0]
            }
            variant={
              getClusterExpiryStateBySupportLevel(cluster) ===
              EnumEolState.Warning
                ? 'brand-secondary'
                : 'destructive-secondary'
            }
          />
        )}
      {isClusterInactive(lastScanDiff, cluster) && <InactiveButton />}
    </>
  );
}

interface UnsupportedButtonProps
  extends React.ComponentProps<typeof IconButton> {
  tooltipText?: string;
  className?: string;
}

export function UnsupportedButton({
  tooltipText = 'EKS v1.19.15 has reached end of support 8 months ago (Aug 1, 2022)',
  className,
  variant,
  ...otherProps
}: UnsupportedButtonProps) {
  return (
    <AtomicTooltip tooltipContent={tooltipText}>
      <IconButton variant={variant} size="small" icon="FeatherClock4" />
    </AtomicTooltip>
  );
}

interface RescanClusterButtonProps
  extends React.ComponentProps<typeof IconButton> {
  className?: string;
  cluster: Cluster;
  showExampleData: boolean;
  isInRescan?: boolean;
  setIsInRescan?: React.Dispatch<React.SetStateAction<boolean>>;
  inactive?: boolean;
  isFirstScanProcessing?: boolean;
}

export function RescanButton({
  className,
  cluster,
  showExampleData,
  isInRescan,
  setIsInRescan,
  inactive,
  isFirstScanProcessing,
  ...otherProps
}: RescanClusterButtonProps) {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();

  const getScanStatus = (cluster: Cluster) => {
    if (isFirstScanProcessing) {
      return false;
    }
    if (cluster && cluster?.last_scan_summary) {
      return (
        Boolean(cluster?.last_scan_summary?.id) &&
        (cluster.rescan === 'completed' || !cluster.rescan)
      );
    } else if (!isInRescan) {
      return true;
    } else {
      return true;
    }
  };
  const [rescanComplete, setRescanComplete] = useState(getScanStatus(cluster));

  useEffect(() => {
    setRescanComplete(getScanStatus(cluster));
  }, [cluster, cluster?.last_scan_summary?.id]);

  const rescanDisabled = useMemo(
    () => rescanComplete === false || (cluster && showExampleData),
    [rescanComplete, cluster],
  );
  const { enqueueSnackbar } = useSnackbar();

  const rescanHandler = async () => {
    setRescanComplete(false);
    try {
      const rescanRequest: RescanClusterRequest = { rescan_status: 'pending' };

      const response = await rescanCluster(cluster.id, rescanRequest, {
        headers: {
          Authorization: `Bearer ${account?.token}`,
        },
      });
      !isInRescan && setIsInRescan && setIsInRescan(true);
      setRescanComplete(response?.rescan === 'completed' || !response?.rescan);
      logEvent('rescan-cluster', {
        cluster_id: cluster.id,
      });
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'rescanning cluster'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    }
  };

  const getTooltipText = () => {
    if (isFirstScanProcessing) {
      return 'Scan in progress';
    }
    if (inactive) {
      return 'Inactive Cluster';
    } else if (isLoading) {
      return 'Rescan in progress';
    } else {
      return 'Rescan Cluster';
    }
  };
  const isLoading = rescanComplete === false;
  return (
    <AtomicTooltip tooltipContent={getTooltipText()}>
      <IconButton
        className={className}
        size="small"
        disabled={isLoading || inactive || isFirstScanProcessing}
        icon="FeatherRotateCw"
        loading={(isLoading && !inactive) || isFirstScanProcessing}
        onContextMenu={(event) => {
          event.preventDefault();
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (!rescanDisabled) {
            rescanHandler();
          }
        }}
        {...otherProps}
      />
    </AtomicTooltip>
  );
}

interface InactiveButtonProps extends React.ComponentProps<typeof IconButton> {
  className?: string;
  loading?: boolean;
}

export function InactiveButton({
  className,
  loading,
  ...otherProps
}: InactiveButtonProps) {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();

  const { user, account } = useUserAccountState();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [inactiveClusterDialogOpen, setInactiveClusterDialogOpen] =
    useState(false);

  const inactiveClusterDialogSubmit = async (message: string) => {
    const requestDetails = {
      title: 'Inactive Cluster Debug Request',
      details: message,
      user_email: user?.email || '',
    };
    logEvent('inactive-cluster-dialog-submit', requestDetails);
    setSubmitLoading(true);
    if (message) {
      try {
        await submitFeedback(requestDetails, {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        });
        enqueueSnackbar(
          `Thanks for the details. We will get back to you shortly on ${user?.email}`,
          {
            variant: 'success',
            autoHideDuration: toastAutoHideDuration,
          },
        );
        setInactiveClusterDialogOpen(false);
      } catch (error) {
        Sentry.captureException(error);
        enqueueSnackbar(
          'Something went wrong while submitting troubleshooting request. Please try again or escalate to Chkk',
          {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          },
        );
      }
    } else {
      enqueueSnackbar('Cannot submit form without details', {
        variant: 'error',
      });
    }
    setSubmitLoading(false);
  };
  const tooltipText = (
    <span>
      {`This cluster is marked Inactive as Chkk can't communicate with it. Click `}
      <a
        href="#"
        style={{ color: 'var(--brand-primary)' }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setInactiveClusterDialogOpen(!inactiveClusterDialogOpen);
        }}
      >
        here
      </a>
      {' to debug the issue with Chkk support.'}
    </span>
  );
  return (
    <div>
      <InactiveClusterDialog
        open={inactiveClusterDialogOpen}
        onSubmit={inactiveClusterDialogSubmit}
        setOpen={setInactiveClusterDialogOpen}
        loading={submitLoading}
      />
      <AtomicTooltip tooltipContent={tooltipText}>
        <IconButton
          className={className}
          variant="destructive-secondary"
          size="small"
          icon="FeatherAlertTriangle"
          loading={loading}
        />
      </AtomicTooltip>
    </div>
  );
}

interface MoreOptionsButtonProps
  extends React.ComponentProps<typeof IconButton> {
  moreOptions?: React.ReactNode;
  className?: string;
  cluster: Cluster;
  mutateClusters?: () => void;
}

export const MoreOptionsButton = React.forwardRef<
  HTMLElement,
  MoreOptionsButtonProps
>(function MoreOptionsButton(
  {
    moreOptions,
    className,
    cluster,
    mutateClusters,
    ...otherProps
  }: MoreOptionsButtonProps,
  ref,
) {
  const { enqueueSnackbar } = useSnackbar();

  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  const propertiesPath = `${basePath}/${cluster?.id}?view=properties`;
  const [clusterNamingDialogOpen, setClusterNamingDialogOpen] = useState(false);
  const [deactivateClusterDialogOpen, setDeactivateClusterDialogOpen] =
    useState(false);

  return (
    <>
      <ChangeClusterNameEnvironmentDialog
        clusterDetails={{
          id: cluster.id,
          name: cluster.name || '',
          environment: cluster.environment || '',
        }}
        allowNameChange={cluster?.agent_managed_properties?.name === undefined}
        allowEnvironmentChange={
          cluster?.agent_managed_properties?.environment === undefined
        }
        onClusterNameEnvironmentChange={() => {
          mutateClusters && mutateClusters();
        }}
        open={clusterNamingDialogOpen}
        onClose={() => {
          setClusterNamingDialogOpen(false);
        }}
      />
      <DeactivateClusterConfirmationDialog
        open={deactivateClusterDialogOpen}
        onClose={() => {
          mutateClusters && mutateClusters();
          setDeactivateClusterDialogOpen(false);
        }}
        clusterId={cluster.id}
        isExampleCluster={isExampleCluster(cluster)}
      />
      <SubframeCore.DropdownMenu.Root>
        <SubframeCore.DropdownMenu.Trigger asChild={true}>
          <IconButton
            className={className}
            ref={ref as any}
            {...otherProps}
            size="small"
            icon="FeatherMoreVertical"
            id="cluster-card-options-button"
            variant="neutral-tertiary"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </SubframeCore.DropdownMenu.Trigger>
        <SubframeCore.DropdownMenu.Portal>
          <SubframeCore.DropdownMenu.Content
            side="bottom"
            align="start"
            sideOffset={8}
            asChild={true}
          >
            <div>
              {moreOptions !== undefined ? (
                moreOptions
              ) : (
                <DropdownMenu>
                  <RouterLink
                    to={propertiesPath}
                    style={{ width: '100%', display: 'contents' }}
                  >
                    <DropdownMenu.DropdownItem
                      icon="FeatherSettings"
                      id="cluster-card-gear-button"
                    >
                      View Properties
                    </DropdownMenu.DropdownItem>
                  </RouterLink>
                  <DropdownMenu.DropdownItem
                    icon="FeatherEdit2"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (
                        cluster?.agent_managed_properties?.environment &&
                        cluster?.agent_managed_properties?.name
                      ) {
                        enqueueSnackbar(errorAgentConfigAlreadySet, {
                          variant: 'warning',
                          autoHideDuration: toastAutoHideDuration,
                        });
                      } else {
                        setClusterNamingDialogOpen(true);
                      }
                    }}
                  >
                    Edit Name &amp; Environment
                  </DropdownMenu.DropdownItem>
                  <div />
                  <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-border" />

                  <DropdownMenu.DropdownItem
                    icon="FeatherX"
                    onClick={(event) => {
                      event.stopPropagation();

                      setDeactivateClusterDialogOpen(true);
                    }}
                  >
                    Deactivate Cluster
                  </DropdownMenu.DropdownItem>
                </DropdownMenu>
              )}
            </div>
          </SubframeCore.DropdownMenu.Content>
        </SubframeCore.DropdownMenu.Portal>
      </SubframeCore.DropdownMenu.Root>
    </>
  );
});

interface OverFlowHandledTextProps {
  text: string;
  maxLength: number;
  maxWidth?: string;
}

export function OverFlowHandledText({
  text,
  maxLength,
  maxWidth,
}: OverFlowHandledTextProps) {
  return (
    <AtomicTooltip tooltipContent={text.length > maxLength ? text : undefined}>
      <div
        style={{
          width: maxWidth || '100px',
          overflow: 'hidden',
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginTop: '2px',
        }}
      >
        {text}
      </div>
    </AtomicTooltip>
  );
}
