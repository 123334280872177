import { useState } from 'react';
import { Divider, Skeleton, Stack, styled, Typography } from '@mui/material';
import { Spacer } from 'components/Spacer';
import Logo from 'components/Logo';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'components/Page';
import { useSnackbar } from 'notistack';
import { Button } from 'subframe/index';
import { toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { acceptInvitation, useGetInvitationByKey } from 'api/frontend';
import { Organization } from 'api/models';
import { Icon } from '@subframe/core';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import TokenService from '@services/TokenService';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'flex-start',
  padding: '0 300px',
  [theme.breakpoints.down('lg')]: {
    padding: '0 100px',
    paddingTop: theme.spacing(5),
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 20px',
    paddingTop: theme.spacing(5),
  },
  paddingTop: theme.spacing(5),
}));

const ContentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '10px 20px',
  boxSizing: 'border-box',
  //   boxShadow: '2px 2px 12px 0px rgba(0,0,0,0.75)',
  boxShadow: '3px -1px 30px 0px rgba(0,0,0,0.75)',
  borderRadius: '10px',
}));

const Heading = styled(Typography)(() => ({
  fontSize: '2.1rem',
  fontWeight: '600',
  lineHeight: '140%',
}));

const SubHeading = styled(Typography)(() => ({
  fontSize: '1.1rem',
  lineHeight: '140%',
}));

const AcceptInvite = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const { getChkkUserToken, setCurrentOrgLocalStorage } = TokenService();
  const [isLoading, setIsLoading] = useState(false);

  const currentOrganizationStorage = JSON.parse(
    localStorage.getItem('currentOrganization') as string,
  ) as Organization | undefined;

  const userToken = getChkkUserToken();

  const {
    data: details,
    error,
    isLoading: detailsLoading,
  } = useGetInvitationByKey(id || '', {
    request: { headers: { Authorization: `Bearer ${userToken}` } },
  });
  const showLoading = detailsLoading && !error && !details;

  if (error) {
    Sentry.captureException(error);
  }

  const handleContinue = () => {
    logEvent('accept-invite-and-return-to-existing-org', {
      previousRoute: document.referrer,
      currentOrg: currentOrganizationStorage?.id,
    });
    // Check if the previous page was from the same host (chkk webapp)
    if (
      document.referrer &&
      document.referrer.includes(window.location.hostname)
    ) {
      // If it was, navigate to previous route
      window.history.back();
    } else {
      // If not, go to the homepage
      navigate('/');
    }
  };

  const acceptInviteHandler = async (id?: string, orgId?: string) => {
    if (id) {
      setIsLoading(true);
      try {
        const response = await acceptInvitation(id);
        if (response.__status === 204) {
          enqueueSnackbar('Invite already accepted', {
            variant: 'success',
            autoHideDuration: toastAutoHideDuration,
          });
        } else {
          enqueueSnackbar('Invite accepted successfully', {
            variant: 'success',
            autoHideDuration: toastAutoHideDuration,
          });
        }
        if (orgId) {
          logEvent('accept-invite-and-continue-to-new-org', { newOrg: orgId });
          const currentOrg = { id: orgId } as Organization;
          setCurrentOrgLocalStorage(currentOrg);
          navigate('/');
        } else {
          handleContinue();
        }
      } catch (error) {
        const response = error.response;
        if (response && response?.data?.message) {
          enqueueSnackbar(response.data.message, {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          });
        } else {
          enqueueSnackbar(
            'Something went wrong while accepting this invitation. Please try again or escalate to Chkk',
            {
              variant: 'error',
              autoHideDuration: toastAutoHideDuration,
            },
          );
        }
        Sentry.captureException(error);
      }
      setIsLoading(false);
    }
  };

  return (
    <Page title="Accept Invite" isIndexable={false}>
      <RootStyle>
        <ContentContainer>
          <Logo style={{ width: '100px', marginBottom: '2px' }} />
          <Spacer value={10} />
          <Divider />
          <Spacer value={30} />
          <Heading>Accept organization invite</Heading>
          <Spacer value={30} />

          {showLoading ? (
            <>
              <Skeleton height={50} />
              <Skeleton height={50} width={'50%'} />
            </>
          ) : (
            <>
              {error ? (
                <Stack spacing={2} alignItems="center">
                  <Icon name="FeatherXCircle" color={'subtext'} />
                  <SubHeading>{'Error fetching Invite Details'}</SubHeading>
                </Stack>
              ) : (
                <>
                  <SubHeading>
                    {details ? (
                      <>
                        <span
                          style={{ display: 'contents' }}
                          className="ph-no-capture"
                        >
                          {details.inviter}
                        </span>{' '}
                        has invited you to their organization (
                        {details.org_name}).{' '}
                      </>
                    ) : (
                      ''
                    )}
                    To continue, please accept this invite.
                  </SubHeading>
                  <Spacer value={40} />

                  <Stack direction={'row'} spacing={4}>
                    <Button
                      size="large"
                      onClick={() => acceptInviteHandler(id, details?.org_id)}
                      type="submit"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      {`Accept & Continue to ${details?.org_name || 'New Org'}`}
                    </Button>
                    {currentOrganizationStorage && (
                      <Button
                        size="large"
                        onClick={() => acceptInviteHandler(id)}
                        type="submit"
                        loading={isLoading}
                        disabled={isLoading}
                        iconRight="FeatherArrowRight"
                        variant="brand-tertiary"
                      >
                        {`Accept & Return to ${currentOrganizationStorage?.name}`}
                      </Button>
                    )}
                  </Stack>
                </>
              )}
            </>
          )}

          <Spacer value={30} />
        </ContentContainer>
      </RootStyle>
    </Page>
  );
};
export default AcceptInvite;
