import React, { useEffect, useState } from 'react';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import * as Sentry from '@sentry/browser';
import { toastAutoHideDuration } from 'constants/toasts';
import { useSnackbar } from 'notistack';
import {
  SSOConfiguration,
  SSOConfigurationStatus,
  SSOProvider,
} from 'api/models';
import {
  createAccountSSOConfiguration,
  updateAccountSSOConfiguration,
} from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { SSOConfigurationStatusEnum } from 'pages/Settings/Security';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import {
  Button,
  Checkbox,
  CodeBlock,
  IconButton,
  StepBase,
  TextArea,
  TextField,
} from 'subframe/index';

interface Props {
  onSubmit: (status: SSOConfigurationStatus) => void;
  ssoConfig: SSOConfiguration;
}

interface SchemaFields {
  clientId: string;
  clientSecret: string;
  oktaDomain: string;
  domainAliases?: string;
}

type ProcessingState = 'pending' | 'in-progress' | 'completed';

interface WorkflowState {
  // processing state
  firstStep: ProcessingState;
  secondStep: ProcessingState;
  thirdStep: ProcessingState;
  firstOpen?: boolean;
  secondOpen?: boolean;
  thirdOpen?: boolean;
}

const ssoConfigSchema = Yup.object().shape({
  clientId: Yup.string().required('Client ID is required'),
  clientSecret: Yup.string().required('Client Secret is required'),
  oktaDomain: Yup.string().required('Okta Domain is required'),
  domainAliases: Yup.string().optional(),
});
const defaultValues: SchemaFields = {
  clientId: '',
  clientSecret: '',
  oktaDomain: '',
  domainAliases: '',
};
const setDefaultValues = (config: any) => {
  if (config?.okta_oidc) {
    return {
      clientId: config?.okta_oidc?.client_id,
      clientSecret: '',
      oktaDomain: config?.okta_oidc?.okta_domain,
      domainAliases: config?.okta_oidc?.domain_aliases?.join(','),
    };
  }
  return defaultValues;
};
const OktaOIDC: React.FC<Props> = ({ onSubmit, ssoConfig }) => {
  const { logEvent } = AnalyticsEventLogger();
  const [loading, setLoading] = useState(false);
  const [hideClientSecret, setHideClientSecret] = useState(false);
  const [workFlowState, setWorkFlowState] = useState<WorkflowState>({
    firstStep: 'in-progress',
    secondStep: 'pending',
    thirdStep: 'pending',
    firstOpen: true,
    secondOpen: false,
    thirdOpen: false,
  });
  const { account } = useUserAccountState();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${account.token}`,
  };
  const SubmissionError =
    'Something went wrong while configuring Okta SSO. Please try again or escalate to Chkk';
  const { enqueueSnackbar } = useSnackbar();
  const RedirectURI = `https://${
    import.meta.env.VITE_AUTH0_DOMAIN || 'auth.us-east-1.aws.chkk.io'
  }/login/callback`;
  const toggleSecret = () => {
    setHideClientSecret(!hideClientSecret);
  };

  const methods = useForm<SchemaFields>({
    resolver: yupResolver(ssoConfigSchema),
    defaultValues: setDefaultValues(defaultValues),
  });

  const {
    handleSubmit: handleSSOSubmit,
    formState: { isSubmitting, errors, isValid },
    trigger: validateForm,
  } = methods;

  useEffect(() => {
    if (errors.clientId || errors.clientSecret) {
      setWorkFlowState((prev) => {
        return {
          ...prev,
          firstOpen: true,
          firstStep: 'pending',
        };
      });
    }
    if (errors.oktaDomain) {
      setWorkFlowState((prev) => {
        return {
          ...prev,
          secondOpen: true,
          secondStep: 'pending',
        };
      });
    }
  }, [errors]);
  const upsertSSORequest = (data: SchemaFields) => {
    const reqBody: any = {
      provider: SSOProvider['okta-oidc'],
      okta_oidc: {
        client_id: data.clientId,
        client_secret: data.clientSecret,
        okta_domain: data.oktaDomain,
        redirect_uris: RedirectURI.split(','),
      },
    };
    if (data.domainAliases && reqBody.okta_oidc) {
      reqBody.okta_oidc.domain_aliases = data.domainAliases.split(',');
    }
    return reqBody;
  };
  const submit = async (data: SchemaFields) => {
    try {
      setLoading(true);
      if (ssoConfig?.created) {
        await updateAccountSSOConfiguration('DEFAULT', upsertSSORequest(data), {
          headers,
        });
        logEvent('security-settings-sso-connection-request-updated', {
          provider: SSOProvider['okta-oidc'],
        });
      } else {
        await createAccountSSOConfiguration('DEFAULT', upsertSSORequest(data), {
          headers,
        });
        logEvent('security-settings-sso-connection-request-created', {
          provider: SSOProvider['okta-oidc'],
        });
      }
      onSubmit(SSOConfigurationStatusEnum.PENDING);
    } catch (error) {
      onSubmit(SSOConfigurationStatusEnum.ERROR);
      Sentry.captureException(error, {
        extra: { data },
      });
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSSOSubmit(submit)}>
        <StepBase
          stepTitle="Create a client for Chkk in your Okta workspace. Provide following details from the client."
          stepNumber="1"
          open={workFlowState.firstOpen}
          onOpenChange={(open) => {
            setWorkFlowState((prev) => {
              return {
                ...prev,
                firstOpen: open,
                firstStep: 'completed',
              };
            });
          }}
          actionButtons={
            <Button
              variant="brand-secondary"
              size="medium"
              icon="FeatherCornerDownRight"
              loading={false}
              disabled={workFlowState.firstStep === 'completed'}
              onClick={() => {
                if (methods.formState.isValid) {
                  setWorkFlowState((prev) => {
                    return {
                      ...prev,
                      firstOpen: false,
                      secondOpen: true,
                    };
                  });
                } else {
                  validateForm();
                  setWorkFlowState((prev) => {
                    return {
                      ...prev,
                      firstOpen: true,
                      firstStep: 'pending',
                    };
                  });
                }
              }}
            >
              Mark as Done
            </Button>
          }
        >
          <div className="flex w-full items-center">
            <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
              Client ID
            </span>
            <TextField
              className="h-auto grow shrink-0 basis-0"
              error={methods.getFieldState('clientId').invalid}
              helpText={errors?.clientId?.message}
            >
              <TextField.Input {...methods.register('clientId')} />
            </TextField>
          </div>
          <div className="flex w-full items-center">
            <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
              Client Secret
            </span>
            <TextField
              className="h-auto grow shrink-0 basis-0"
              error={methods.getFieldState('clientSecret').invalid}
              helpText={errors?.clientSecret?.message}
            >
              <div className="flex w-full grow shrink-0 basis-0 items-center justify-center">
                <TextField.Input
                  type={hideClientSecret ? 'text' : 'password'}
                  {...methods.register('clientSecret')}
                />
                <IconButton
                  id="security-settings-toggle-secret"
                  icon={hideClientSecret ? 'FeatherEye' : 'FeatherEyeOff'}
                  size="small"
                  onClick={() => toggleSecret()}
                />
              </div>
            </TextField>
          </div>
        </StepBase>
        <StepBase
          stepTitle="Setup Domain"
          stepNumber="2"
          open={workFlowState.secondOpen}
          onOpenChange={(open) => {
            setWorkFlowState((prev) => {
              return { ...prev, secondOpen: open };
            });
          }}
          actionButtons={
            <Button
              variant="brand-secondary"
              size="medium"
              icon="FeatherCornerDownRight"
              loading={false}
              disabled={
                workFlowState.secondStep === 'completed' ||
                workFlowState.firstStep !== 'completed'
              }
              onClick={() => {
                if (methods.formState.isValid) {
                  setWorkFlowState((prev) => {
                    return {
                      ...prev,
                      secondStep: 'completed',
                      secondOpen: false,
                      thirdOpen: true,
                    };
                  });
                } else {
                  validateForm();
                  setWorkFlowState((prev) => {
                    return {
                      ...prev,
                      thirdOpen: false,
                      secondStep: 'pending',
                      secondOpen: true,
                    };
                  });
                }
              }}
            >
              Mark as Done
            </Button>
          }
        >
          <div className="flex w-full items-center">
            <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
              Okta Domain
            </span>
            <TextField
              className="h-auto grow shrink-0 basis-0"
              error={methods.getFieldState('oktaDomain').invalid}
              helpText={errors?.oktaDomain?.message}
            >
              <TextField.Input {...methods.register('oktaDomain')} />
            </TextField>
          </div>
          <div className="flex w-full items-start">
            <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
              Domain Aliases
            </span>
            <TextArea
              className="h-auto grow shrink-0 basis-0"
              error={methods.getFieldState('domainAliases').invalid}
              helpText={errors?.domainAliases?.message}
            >
              <div className="flex w-full grow shrink-0 basis-0 flex-col items-start">
                <TextArea.Input
                  className="min-h-[96px] w-full grow shrink-0 basis-0"
                  {...methods.register('domainAliases')}
                />
              </div>
            </TextArea>
          </div>
        </StepBase>
        <StepBase
          stepTitle="Configure the client in your Okta workspace"
          stepNumber="3"
          open={workFlowState.thirdOpen}
          onOpenChange={(open) => {
            setWorkFlowState((prev) => {
              return { ...prev, thirdOpen: open };
            });
          }}
          lastStep={true}
          actionButtons={
            <Button
              variant="brand-secondary"
              size="medium"
              icon="FeatherCornerDownRight"
              loading={false}
              disabled={
                workFlowState.thirdStep === 'completed' ||
                workFlowState.secondStep !== 'completed'
              }
              onClick={() => {
                setWorkFlowState((prev) => {
                  return {
                    ...prev,
                    thirdStep: 'completed',
                    thirdOpen: false,
                  };
                });
              }}
            >
              Mark as Done
            </Button>
          }
        >
          <div className="flex w-full items-center">
            <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
              Configure Redirect URI
            </span>
            <CopyToClipboard
              className="h-auto grow shrink-0 basis-0"
              text="https://auth.us-east-1.aws.chkk.io/login/callback"
            />
          </div>
          <div className="flex w-full items-center">
            <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
              Unselect
            </span>
            <div className="flex grow shrink-0 basis-0 items-center bg-neutral-50">
              <div className="flex grow shrink-0 basis-0 items-center">
                <div className="flex items-center pl-2">
                  <Checkbox label="" disabled={true} />
                </div>
                <CodeBlock className="h-auto grow shrink-0 basis-0">
                  &quot;User Consent: Require Consent&quot;
                </CodeBlock>
              </div>
            </div>
          </div>
        </StepBase>
        <Button
          icon="FeatherPlus"
          data-cy="add-okta-sso"
          loading={loading}
          disabled={loading || isSubmitting}
          onClick={handleSSOSubmit(submit)}
        >
          {(ssoConfig?.created ? 'Update' : 'Add')
            .concat(' ')
            .concat('SSO Connection')}
        </Button>
      </FormProvider>
    </>
  );
};

export default OktaOIDC;
